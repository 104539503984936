<template>
  <div>
    <b-modal
      v-model="modalPickList"
      title="Select Pick List"
      hide-header-close
      size="lg">
      <b-row>
        <b-col>
          <b-form-group>
            <span>Picklist</span>
            <v-select
              v-model="selectedPickLine"
              placeholder="----- Select Picklist -----"
              label="pickListLineNumber"
              multiple
              :options="pickLines"
              :clearable="false"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button variant="success" @click="addPickList">
          Add
        </b-button>
        <b-button variant="danger" @click="hideModal">
          Cancel
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="modalConfirmationAddPickList"
      title="Selected PickList to be Added"
      hide-header-close
      size="md"
      id="modal-scrollable" 
      scrollable>
      <span style="margin-bottom: 10px;"><b>List of PickList</b></span>
      <br>
      <br>
      <b-row v-for="(data,i) in selectedPickLine" :key="i" style="margin-left: 10px;">
        <b-col cols="3">
          <span><b>PickList</b></span>
        </b-col>
        <b-col cols="1">
          <span>:</span>
        </b-col>
        <b-col cols="8">
          <span>{{ data.pickListLineNumber }}</span>
        </b-col>
      </b-row>
      <p class="my-4 justify-content-md-center"> Are you sure want to add this PickLists?</p>
      <template #modal-footer>
        <b-button variant="success" @click="confirmAddPickList">
          Add
        </b-button>
        <b-button variant="danger" @click="hideModal">
          Cancel
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="modalConfirmCancelPicklist"
      title="Cancel Pick List"
      hide-header-close
      size="md"
      id="modal-scrollable">
      <span style="margin-bottom: 10px;"><b>Are you sure want to cancel the selected PickList?</b></span>
      <template #modal-footer>
        <b-button variant="success" @click="confirmCancelPickList">
          Ok
        </b-button>
        <b-button variant="danger" @click="modalConfirmCancelPicklist = false">
          Cancel
        </b-button>
      </template>
    </b-modal>

    <b-card title="Update Pick List">
      <b-row>
        <b-col cols="12" style="margin-bottom: 15px;">
          <b-button
            variant="primary"
            :to="{ name: 'ongoing-job-order' }"
            >
            Back to Job Order
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-row >
        <b-col cols="4">
          <span><b>Job Order Number</b></span>
        </b-col>
        <b-col cols="1">
          <span>:</span>
        </b-col>
        <b-col cols="7">
          <span>{{ this.form.orderNumber }}</span>
        </b-col>

        <b-col cols="4">
          <span><b>Type</b></span>
        </b-col>
        <b-col cols="1">
          <span>:</span>
        </b-col>
        <b-col cols="7">
          <span>{{ this.form.props.type }}</span>
        </b-col>

        <b-col cols="4">
          <span><b>Created At</b></span>
        </b-col>
        <b-col cols="1">
          <span>:</span>
        </b-col>
        <b-col cols="7">
          <span>{{ dateFormat(this.form.createdAt) }}</span>
        </b-col>

        <b-col cols="4">
          <span><b>Updated At</b></span>
        </b-col>
        <b-col cols="1">
          <span>:</span>
        </b-col>
        <b-col cols="7">
          <span>{{ dateFormat(this.form.updatedAt) }}</span>
        </b-col>
      </b-row><hr/>
      <b-row>
        <b-col offset-md="10">
          <b-button variant="primary" @click="showModalPickList" v-if="form.props.status < 60">
            Add PickList
          </b-button>
        </b-col>
      </b-row>
      <b-row style="margin-bottom:10px" class="show-on-mobile">
        <b-col>
          <b-form-checkbox
            v-model="stackedStatus"
            value="md"
            unchecked-value="false"
          >
            Stacked Table
          </b-form-checkbox>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <div style="overflow-x: visible; margin-top: 15px;">
            <b-table
              hover
              responsive
              small
              head-variant="dark"
              outlined
              :items="item"
              :fields="fieldsItems"
              :stacked="stackedStatus"
            >
            <template v-slot:cell(actions)="row">
              <b-col>
                <b-button variant="warning" size="sm" 
                  @click="cancelPickList(row.index)"
                  :disabled="row.item.pickList.props.statusNo >= 35"
                  >
                  Cancel PickList
                </b-button>
              </b-col>
            </template>
            </b-table>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { dateFormat } from "@/utils/utils";

export default {
  components: {
    vSelect,
  },
  props:["id"],
  data() {
    return {
      mamadrumID:'',
      mamadrumName:'',
      item:[],
      selectedMamaDrum:'',
      selectedPickLine:[],
      picklistData:[],

      modalPickList: false,
      modalConfirmationAddPickList: false,
      modalConfirmCancelPicklist:false,

      stackedStatus:"md",
      form:{
        props:{
          type:'',
          app:'',
          status:0,
        }
      },
      fieldsItems:[
        {key:"orderNumber", label:"Picklist Number", filterable:false, sortable:false},
        {key:"quantity", label:"Quantity", filterable:false, sortable:false},
        {key:"pickList.props.statusNo", label:"Status Number", filterable:false, sortable:false},
        {key: "actions", label: "Actions" },
      ],
      listPickline:[],
      fromBatch: "",
      // picklistType: "",

    }
  },
  mounted() {
    //this.$store.dispatch("item/fetchItem");
    this.$store.dispatch("picklist/getUpdatePicklistJobOrder");
    // this.$store.dispatch("bom/getItems");
    // this.$store.dispatch("joborder/getJobOrder");
    
    this.$store.dispatch("joborder/getJobOrderId", { id: this.id })
    .then( () => {
      this.form = this.$store.getters["joborder/getJobOrderId"]
      // this.form.createdAt = dateFormat(this.form.createdAt) 
      console.log(this.form.createdAt);
      this.item = this.form.item
      this.fromBatch = this.form.drumID
      // this.picklistType = this.form.item[0].pickList.pickListType
    })
  },
  computed: {
    pickLines() {
      if (this.form.props.type == 'Cutting') {
        // Mengambil daftar picklist dari store
        let picklistItems = this.$store.getters["picklist/getUpdatePicklistJobOrder"]
          .filter((picklist) => picklist.fromBatch == this.fromBatch)
          .map((el) => {
            return {
              ...el,
              pickListLineNumber: el.picklistNo + '_' + el.picklistLine
            };
          });
        
        // Filter picklistItems berdasarkan item yang sudah dipilih
        let selectedPicklistItems = this.selectedPickLine.map((selectedItem) => {
          return selectedItem.pickListLineNumber;
        });
      
        // Mengembalikan daftar picklist yang belum dipilih
        return picklistItems.filter((item) => {
          return !selectedPicklistItems.includes(item.pickListLineNumber);
        });
      }
      return [];
    }
  },
  methods: {
    dateFormat(date) {
      return dateFormat(date)
    },
    showModalPickList() {
      this.modalPickList = true
    },
    hideModal() {
      this.modalPickList = false
      this.modalConfirmationAddPickList = false
      this.selectedMamaDrum = ''
      this.selectedPickLine = [] 
    },
    cancelPickList(index) {
      console.log("Cancel PickList method called with index:", index);
      this.picklistNumber = this.form.item[index].orderNumber
      this.indexPicklist = index
      this.modalConfirmCancelPicklist = true
    },
    async confirmCancelPickList(){
      let itemArr = this.form.item.map((el) => {
          return {
            quantity: el.quantity,
            orderNumber: el.orderNumber,
            pickList: el.pickList.id,
            _id: el._id,
          };
        });

      await this.$store.dispatch("joborder/cencelPicklistJobOrder",{
        id: this.id,
        data: {
          item: itemArr,
          index: this.indexPicklist,
          picklistCancel:this.picklistNumber
        }
      })
      .then(() => {
        this.modalConfirmCancelPicklist = false
        this.$store.dispatch("picklist/getUpdatePicklistJobOrder");
        this.$bvToast.toast("Successfully Cancel Pickline", {
          title: "Success",
          variant: "success",
          solid: true,
        });
        this.$store.dispatch("joborder/getJobOrderId", { id: this.id })
        .then( () => {
          this.form = this.$store.getters["joborder/getJobOrderId"]
          this.item = this.form.item
        })
      })
      .catch((err) => {
        this.$bvToast.toast("Failed to cancel PickList", {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        this.$store.dispatch("joborder/getJobOrderId", { id: this.id })
        .then( () => {
          this.form = this.$store.getters["joborder/getJobOrderId"]
          this.item = this.form.item
        })
        this.modalConfirmCancelPicklist = false
      });
    },
    addPickList() {
      this.modalConfirmationAddPickList = true
    },
    async confirmAddPickList (){
      let itemArr = this.form.item.map((el) => {
          return {
            quantity: el.quantity,
            orderNumber: el.orderNumber,
            pickList: el.pickList.id,
            _id: el._id,
          };
        });
      await this.$store.dispatch("joborder/addPicklistJobOrder",{
        id: this.id,
        data: {
          item: itemArr,
          selectedPickLine: this.selectedPickLine,
          tempStatus:'not pickable'
        }
      })
      .then(() => {
        this.$store.dispatch("picklist/getUpdatePicklistJobOrder");

        // this.$store.dispatch("picklist/getPicklist");
        this.form = this.$store.getters["joborder/getJobOrderId"]
        this.mamadrumID = this.form.drumID
        this.item = this.form.item
        this.$bvToast.toast("Successfully add pick list", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          this.$store.dispatch("joborder/getJobOrderId", { id: this.id })
          .then( () => {
            this.form = this.$store.getters["joborder/getJobOrderId"]
            this.item = this.form.item
          })
          this.hideModal()
          this.modalConfirmationAddPickList = false
      })
      .catch((err) => {
        console.log(err);
        this.$bvToast.toast("Failed to add PickList", {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        this.$store.dispatch("joborder/getJobOrderId", { id: this.id })
          .then( () => {
            this.form = this.$store.getters["joborder/getJobOrderId"]
            this.item = this.form.item
          })
        this.hideModal()
        this.modalConfirmationAddPickList = false
        return
      });
    },
  }
}
</script>

<style>
@media (min-width: 761px) {
  .show-on-mobile {
    display: none !important;
  }
}
</style>